import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import LinkRouter from "./utils/LinkRouter";
import { LinkProps } from "@mui/material/Link";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const jamesonTheme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkRouter,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkRouter,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // backgroundImage: "url(/img/jameson.svg)",
          backgroundBlendMode: "soft-light",
          backgroundSize: "70px",
        },
      },
    },
  },

  palette: {
    background: {
      default: "#0e6137",
      paper: "#fef8e1",
    },

    primary: {
      main: "#0e6137",
      contrastText: "#f1e6b2",
    },
    secondary: {
      main: "#0e6137",
      contrastText: "#f1e6b2",
    },
  },
});
export const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkRouter,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkRouter,
      },
    },
  },
  palette: {
    primary: {
      main: "#233456",
      contrastText: "#fafafa",
    },
    secondary: {
      main: "#334561",
      contrastText: "#fafafa",
    },
  },
});

const getInitialStore = () => {
  return {
    // user: {
    //   token: localStorage.getItem("token") || "",
    // },
  };
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store(getInitialStore())}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
