import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

const InputText = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> & TextFieldProps
) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const { size, margin, fullWidth, ...rest } = props;
  return (
    <TextField
      {...field}
      error={!!error}
      helperText={error && error.message && error.message}
      fullWidth={fullWidth === undefined ? true : fullWidth}
      inputRef={field.ref}
      {...rest}
    />
  );
};

export default InputText;
