import {
  Action,
  ThunkAction,
  configureStore,
  PreloadedState,
  combineReducers,
} from "@reduxjs/toolkit";
import CluesReducer from "../components/CluesList/Clues.slice";
import LocationsReducer from "./Location.slice";
import UsersReducer from "./Users.slice";
import AuthReducer from "./Auth.slice";
import GameConfigReducer from "./GameConfig.slice";
import LeaderBoardReducer from "./LeaderBoard.slice";

export const reducersList = {
  auth: AuthReducer,
  clues: CluesReducer,
  locations: LocationsReducer,
  users: UsersReducer,
  gameConfig: GameConfigReducer,
  leaderboard: LeaderBoardReducer,
};

export const rootReducer = combineReducers(reducersList);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default setupStore;
