import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/apiClient";
import { ISignInForm } from "../pages/SignIn/SignIn.page";
import { AxiosError } from "axios";

export interface AuthState {
  token: string | null;
  isLoading: boolean;
  error: string | undefined;
}
const initialToken = localStorage.getItem("userToken") || null;

const initialAuthState: AuthState = {
  // user: null,
  token: initialToken,
  isLoading: false,
  error: undefined,
};
export const logIn = createAsyncThunk(
  "auth/logIn",
  async (data: ISignInForm, { rejectWithValue }) => {
    console.log("datadis", data);
    const response = await axiosClient.post("/login", data);
    console.log("response", response);
    if (response.status !== 200) {
      return rejectWithValue(response.data);
    }
    console.log("response.data", response.data);
    localStorage.setItem("userToken", response.data.token);
    return response.data;
  }
);
export const logOut = createAsyncThunk("auth/logOut", async () => {
  localStorage.removeItem("userToken");
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.fulfilled, (state, action) => {
        console.log("action.payload", action);
        // state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.token = "";
      })
      .addCase(logIn.rejected, (state, action) => {
        state.token = "";
      })
      .addMatcher(isPending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
        state.error = "Error: " + action.error.message;
      });
  },
});
export const { setUserToken } = AuthSlice.actions;

export default AuthSlice.reducer;
