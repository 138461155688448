import React, { useEffect } from "react";
import { selectUsers } from "../../store/Users.slice";
import { Box, Chip, Grid } from "@mui/material";
import InputText from "../InputText/InputText";
import SelectBox from "../SelectBox/SelectBox";
import { useAppSelector } from "../../store/redux.hooks";
import { useLocationsHook } from "../../hooks/useLocations.hook";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { roleOptions, UserFormType, UserRoles } from "../Users/Users.types";
import { CrudForm } from "../../utils/types";

const UserForm = ({
  form,
  onSubmit,
  isDisabled = false,
  defaultValues,
}: CrudForm<UserFormType>) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    register,

    formState: { errors, isDirty, isValid },
  } = form;
  const users = useAppSelector(selectUsers);
  const { locationsVariants } = useLocationsHook();
  const watchRole = watch("role");
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);
  useEffect(() => {
    watchRole === "admin" && setValue("adminId", undefined);
  }, [watchRole]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-disabled={isDisabled}>
      {/*{errors?.map((err) => (*/}
      {/*  <div key={err}>{err}</div>*/}
      {/*))}*/}

      <Grid spacing={2} container>
        <Grid item xs={6}>
          {/*<input {...register("name")} />*/}
          <InputText
            fullWidth
            name={"teamName"}
            label={"Team name"}
            variant={"outlined"}
            control={control}
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <InputText
            control={control}
            fullWidth={true}
            name="code"
            variant="outlined"
            label={"Code"}
          />
        </Grid>
        <Grid item xs={6}>
          <InputText
            control={control}
            fullWidth={true}
            name="verificationCode"
            variant="outlined"
            label={"Verification Code"}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBox
            name={"role"}
            control={control}
            options={roleOptions}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBox
            disabled={watchRole === UserRoles.enum.admin}
            name={"adminId"}
            control={control}
            options={users
              .filter((user) => user.role === UserRoles.enum.admin)
              .map((user) => ({
                value: user._id,
                label: user.teamName || "code: " + user.code,
              }))}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectBox
            name={"playingLocationSteps"}
            control={control}
            options={locationsVariants}
            multiple
            renderValue={(selected) => {
              if (Array.isArray(selected)) {
                const selectedLocations = locationsVariants.filter((location) =>
                  selected.includes(location.value)
                );
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedLocations.map((value, i) => (
                      <>
                        <Chip
                          key={value.value}
                          label={`(${i + 1}) ${value.label}`}
                        />
                        {i < selectedLocations.length - 1 && (
                          <ArrowForwardIosIcon />
                        )}
                      </>
                    ))}
                  </Box>
                );
              }
            }}
          />
        </Grid>

        {/*<Grid item xs={12}></Grid>*/}
        {/*<Grid item xs={3}>*/}
        {/*  <Button type={"submit"} variant={"contained"} disabled={isDisabled}>*/}
        {/*    Submit*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
      </Grid>
    </form>
  );
};
export default UserForm;
