import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  addClueService,
  deleteClueService,
  getCluesService,
  updateClueService,
} from "../../services/ClueService/Clue.service";
import { RootState } from "../../store/store";
import { z } from "zod";
import { UserRoles } from "../Users/Users.types";

// const initialValues: ClueFormSchema = {
//   _id: "",
//   name: "",
//   description: "",
//   fullDescription: "",
//   point: 0,
//   location: "",
//   locationFromGoogle: "",
//   clueType: "s",
//   maxPlayersSameTime: 0,
//   playTime: 0,
//   gameId: 0,
//   fileName: "",
// };
// export const clueTypes = z.enum(["standardGame", "levelUp", "finalClue"]);
export const clueTypes = z.enum(["standardGame", "levelUp"]);

export const clueTypesOptions = Object.entries(clueTypes.enum).map(
  ([key, role]) => ({
    value: key,
    label: role,
  })
);

export const validationSchema = z.object({
  _id: z.string().optional(),
  id: z.string().optional(),
  name: z.string().nonempty(),
  description: z.string().nonempty(),
  fullDescription: z.string().nonempty(),
  point: z.coerce.number().nonnegative(),
  locationId: z.string().nonempty(),
  locationFromGoogle: z.union([
    z.string().max(0, { message: "Please select location from google" }),
    z.string().regex(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/),
  ]),
  clueType: clueTypes,
  maxPlayersSameTime: z.coerce.number().nonnegative(),
  playTime: z.coerce.number().nonnegative(),
  clueCode: z.string().optional(),
  fileName: z.string().optional(),
  file: z.instanceof(File).nullable().optional(),
});
export type ClueFormSchema = z.infer<typeof validationSchema>;
export type ClueSchema = ClueFormSchema;

interface ClueState {
  items: ClueSchema[];
  isLoading: boolean;
  error: string | null;
}
export const emptyClue: ClueSchema = {
  _id: "",
  id: "",
  name: "",
  description: "",
  fullDescription: "",
  point: 0,
  locationId: "",
  locationFromGoogle: "",
  clueType: clueTypes.enum.standardGame,
  maxPlayersSameTime: 0,
  playTime: 0,
  clueCode: "",
  fileName: "",
};
export const initialClueState: ClueState = {
  items: [],
  isLoading: false,
  error: null,
};

export const getClues = createAsyncThunk("clues/getClues", async () => {
  const response = await getCluesService();
  return response;
});
export const addClue = createAsyncThunk(
  "clues/addClue",
  async (clue: ClueSchema) => {
    const response = await addClueService(clue);
    return response.data;
  }
);
export const updateClue = createAsyncThunk(
  "clues/updateClue",
  async (clue: ClueSchema) => {
    const response = await updateClueService(clue);
    return response.data;
  }
);
export const deleteClue = createAsyncThunk(
  "clues/deleteClue",
  async (id: ClueSchema["_id"]) => {
    const response = await deleteClueService(id);
    return response.data;
  }
);
export const CluesSlice = createSlice({
  name: "clues",
  initialState: initialClueState,
  reducers: {
    // setClues: (state: ClueState, action: PayloadAction<ClueSchema[]>) => {
    //   return action.payload;
    // },
    // setToast: (
    //   state: ToastState,
    //   action: PayloadAction<Omit<ToastState, "open">>
    // ) => {
    //   state.message = action.payload.message;
    //   state.type = action.payload.type || "error";
    //   state.open = true;
    // },
    // hideToast: (state: ToastState) => {
    //   console.log("hideToast");
    //   state.open = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getClues.fulfilled,
        (state, { payload }: PayloadAction<ClueSchema[]>) => {
          state.items = payload;
        }
      )
      .addCase(updateClue.fulfilled, ({ items }, action) => {
        console.log(action, "action");
        //change the state from back end. Now it coming something wrong
        const data = action.meta.arg;
        const index = items.findIndex((item) => item._id === data._id);
        items[index] = data;
      })
      .addCase(addClue.fulfilled, ({ items }, action) => {
        items.push(action.meta.arg);
      })
      .addCase(deleteClue.fulfilled, (state, action) => {
        const data = action.meta.arg;
        state.items = state.items.filter((item) => item._id !== data);
      })
      .addMatcher(isPending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isRejected, (state) => {
        state.isLoading = false;
        state.error = "Request was rejected";
      });
  },
});

export const {} = CluesSlice.actions;
export default CluesSlice.reducer;

export const selectClues = (state: RootState) => state.clues.items;
