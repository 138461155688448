import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type FormDialogProps = {
  title: string;
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  actions?: React.ReactNode[];
  isLoading?: boolean;
  isDisabled?: boolean;
  // onSubmit: () => void;
  // form: UseFormReturn<any>;
  // onSubmit: (data: any) => void;
};
const FormDialog = ({
  title,
  children,
  open,
  handleClose,
  actions,
  isLoading,
}: // onSubmit,
// form,
// onSubmit,
FormDialogProps) => {
  // const { handleSubmit } = form;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
      <DialogTitle>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ position: "relative" }}>
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading || false}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {children}
        </>
      </DialogContent>

      <DialogActions>
        <>
          {actions?.map((action) => action)}
          <Button color={"secondary"} onClick={handleClose}>
            Close Dialog
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
