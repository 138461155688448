import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const MainPage = () => {
  return (
    <div>
      <Typography variant="h2" component="h1"></Typography>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Hello there! It is admin part of the app quest
        </Typography>
      </Box>
    </div>
  );
};

export default MainPage;
