import React, { useEffect } from "react";
import ClueForm from "../../components/ClueForm/ClueForm.component";
import { GridRowId } from "@mui/x-data-grid";

import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import {
  addClue,
  ClueFormSchema,
  ClueSchema,
  deleteClue,
  emptyClue,
  getClues,
  updateClue,
  validationSchema,
} from "../../components/CluesList/Clues.slice";
import FormDialog from "../../components/FormDialog/FormDialog.component";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { Button, Typography } from "@mui/material";
import GridList from "../../components/GridList/GridList.component";
import { GridCol } from "../../utils/types";
import { useLocationsHook } from "../../hooks/useLocations.hook";
import { filesBaseUrl } from "../../utils/filesBaseUrl";
import { toast } from "react-toastify";

// const useClueFormModal = () => {
//   const [open, setOpen] = React.useState(false);
//   const [defaultValues, setDefaultValues] = React.useState<
//     ClueFormSchema | undefined
//   >(undefined);
//   const handleOpen = (defaultValues?: ClueFormSchema) => {
//     setOpen(true);
//     setDefaultValues(defaultValues);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   return { open, handleOpen, handleClose, defaultValues };
// };
const CluesPage = () => {
  // const [, setClues] = useState<ClueSchema[]>(initialRows);
  const {
    items: clues,
    isLoading,
    error,
  } = useAppSelector((state) => state.clues);
  const dispatch = useAppDispatch();
  const [openedID, setOpenedID] = React.useState<ClueSchema["_id"]>("");
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const openClueForm = (id: ClueSchema["_id"]) => {
    setOpenedID(id);
  };

  const handleEditClick = (id: GridRowId) => {
    openClueForm(id as ClueSchema["_id"]);
    setDialogOpen(true);
    reset();
  };
  const handleAddClick = () => {
    console.log("handleAddClikck");
    openClueForm("");
    setDialogOpen(true);
    setOpenedID("");
    reset();
  };
  useEffect(() => {
    dispatch(getClues());
  }, []);
  // useEffect(() => {
  //   if (error !== undefined) {
  //     setDialogOpen(false);
  //   }
  // }, [isLoading]);

  const onSubmit = (data: ClueFormSchema) => {
    if (openedID?.length === 0) {
      dispatch(addClue(data))
        .unwrap()
        .then(() => {
          toast.success("Clue added successfully");
        });
    } else {
      dispatch(updateClue(data))
        .unwrap()
        .then(() => {
          toast.success("Clue updated successfully");
        });
    }
  };
  const formMethods = useForm<ClueFormSchema>({
    resolver: zodResolver(validationSchema),
    // defaultValues: emptyClue,
    values: { ...emptyClue, ...clues.find((item) => item._id === openedID) },
  });
  const { handleSubmit, reset } = formMethods;
  const { locationsVariants } = useLocationsHook();
  const columns: GridCol<ClueSchema>[] = [
    { field: "clueCode", headerName: "Code" },
    { field: "name", editable: true, headerName: "Name" },
    { field: "description", editable: true, headerName: "Description" },
    { field: "fullDescription", headerName: "Description" },
    { field: "locationFromGoogle", headerName: "Location from Google" },
    { field: "point", editable: true, headerName: "Point" },

    { field: "clueType", editable: true, headerName: "Type" },
    { field: "playTime", editable: true, headerName: "Type" },
    {
      field: "maxPlayersSameTime",
      editable: true,
      headerName: "Max players same time",
    },
    {
      field: "fileName",
      headerName: "File",
      width: 200,
      renderCell: ({ value }) => {
        return (
          <>
            <Button target={"_blank"} href={filesBaseUrl + value}>
              {value}
            </Button>
          </>
        );
      },
    },
    {
      field: "locationId",
      editable: true,
      headerName: "Location",
      type: "singleSelect",
      valueOptions: locationsVariants,
    },
  ];

  const handleDeleteClick = (id: GridRowId) => {
    dispatch(deleteClue(id as ClueSchema["_id"]))
      .unwrap()
      .then(() => {
        toast.success("Clue deleted successfully");
      });
  };
  return (
    <div>
      <Typography variant={"h3"}>Clues</Typography>

      {/*<CluesList initialRows={clues} handleEditClick={handleEditClick} />*/}
      <GridList<ClueSchema>
        columns={columns}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddClick={handleAddClick}
        rows={clues}
      />
      <FormDialog
        title={"Clue"}
        open={dialogOpen}
        isLoading={isLoading}
        isDisabled={error !== undefined}
        // onSubmit={handleSubmit(onSubmit)}
        handleClose={() => {
          setDialogOpen(false);
        }}
        actions={[
          <Button
            key={"1"}
            color={"primary"}
            variant={"contained"}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Save
          </Button>,
        ]}
      >
        <ClueForm
          onSubmit={onSubmit}
          defaultValues={clues.find((item) => item._id === openedID)}
          form={formMethods}
        />
      </FormDialog>
    </div>
  );
};

export default CluesPage;
