import axiosClient from "../../utils/apiClient";
import { ClueFormSchema } from "../../components/CluesList/Clues.slice";

export async function getCluesService() {
  const clue = await axiosClient.get(`/clue`);
  return clue.data;
}

export function addClueService(clue: ClueFormSchema) {
  return axiosClient.post(`/clue`, clue);
}

export function updateClueService(clue: ClueFormSchema) {
  // formData.append("data", JSON.stringify(data));
  return axiosClient.put(`/clue`, clue);
}

export function deleteClueService(id: ClueFormSchema["_id"]) {
  return axiosClient.delete(`/clue/${id}`);
}
