import React from "react";

const AdminPage = () => {
  // const { user } = useAuth();

  return (
    <div>
      <h1>Admin Page</h1>
      <p>Logged in as: Admin</p>
    </div>
  );
};

export default AdminPage;
