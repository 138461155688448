import React from "react";
import LeaderBoard from "../../features/LeaderBoard/LeaderBoard";
import {
  Box,
  Container,
  CssBaseline,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { useAppSelector } from "../../store/redux.hooks";
import { selectAllLocations } from "../../store/Location.slice";
import { Link, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinkRouter from "../../utils/LinkRouter";
import { jamesonTheme } from "../../index";
const BottlePage = () => {
  return (
    <>
      <ThemeProvider theme={jamesonTheme}>
        <CssBaseline enableColorScheme />
        <Box
          sx={{
            display: "flex",
            minHeight: "80vh",
            py: "20px",
            // my: "20px",
          }}
        >
          <Paper
            elevation={0}
            sx={{ p: "20px", margin: "auto", minHeight: "50vh" }}
          >
            <h2>
              Տարբերիր իրականը կեղծից: Գտիր սխալը և մուտքագրիր ճիշտը
              telegram-ում
            </h2>
            <Container>
              <img
                style={{
                  maxWidth: "100%",
                }}
                // sx={{
                //   maxWidth: "80vw",
                // }}
                src={"/img/bottle.png"}
                alt={"bottle"}
              />
            </Container>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};
export default BottlePage;
