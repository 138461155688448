import { z } from "zod";

// export enum UserRoles {
//   admin = "admin",
//   player = "player",
// }
export const UserRoles = z.enum(["admin", "player"]);
export type UserRolesType = keyof typeof UserRoles;
export const roleOptions = Object.entries(UserRoles.enum).map(
  ([key, role]) => ({
    value: key,
    label: role,
  })
);
export const userSchema = z.object({
  teamName: z.string(),
  // id: z.string(),
  _id: z.string(),
  role: UserRoles,
  admin: z.string().optional(),
  adminId: z.string().optional(),
  // chatTo: z.string().optional(),
  code: z.string().optional(),
  verificationCode: z.string().optional(),
  playingLocationId: z.union([z.string(), z.null()]),
  playingLocationSteps: z.array(z.string()).optional(),
  playingLocationStepsNames: z.array(z.string()).optional(),
  playingClueId: z.string().optional().nullable(),
  playingClueTime: z.coerce.number().optional().nullable(),
  playingLocationTime: z.coerce.number().optional().nullable(),
  playedGames: z.array(z.string()).optional(),
  playStatus: z.string().optional(),
  userPoint: z.coerce.number().optional(),
  allPoint: z.coerce.number().optional(),
});

export type User = z.infer<typeof userSchema>;

export interface UsersState {
  items: User[];
  isLoading: boolean;
  error: string | null;
}

export const userFormSchema = z
  .object({
    teamName: z.string().optional(),
    role: UserRoles,
    // phone_number: z.coerce.number().optional(), //???
    // admin: z.string(),
    adminId: z.string().optional(),

    code: z.string().nonempty(),
    verificationCode: z.string().nonempty(),
    playingLocationSteps: z.array(z.string()).min(1),
  })
  .superRefine((values, ctx) => {
    if (values.role === UserRoles.enum.player && !values.adminId) {
      ctx.addIssue({
        message: "Պարտադիր, եթե դերը 'player' Է",
        code: z.ZodIssueCode.custom,
        path: ["adminId"],
      });
    }
  });

export type UserFormType = z.infer<typeof userFormSchema>;
// const UserState = z.object({});
// export type UserState = z.infer<typeof UserState>;

export type NewUserForm = UserFormType; // & {};
export type UpdatedUserForm = UserFormType & {
  // id: string;
  _id: string;
};
// export interface UserState {}
