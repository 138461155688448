import { useAppSelector } from "../store/redux.hooks";

export interface LoggedInUser {
  loggedIn: true;
  token: string;
  name: string;
}

interface NotLoggedInUser {
  loggedIn: false;
  name: "";
  token: "";
  error?: string;
}

export type Admin = LoggedInUser | NotLoggedInUser;

export const useAuthAdmin = (): Admin => {
  const { token } = useAppSelector((state) => state.auth);

  if (token) {
    return { loggedIn: true, name: "Admin", token };
  } else {
    return { loggedIn: false, name: "", token: "" };
  }
};
