import { AlertColor } from "@mui/material";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/apiClient";
import { RootState } from "./store";
import { z } from "zod";

export const locationValidationSchema = z.object({
  _id: z.string(),
  id: z.string(),
  name: z.string().nonempty(),
  startDescription: z.string().nonempty(),
  finishTime: z.coerce.number().min(0),
  finishPoint: z.coerce.number().min(0),
  needToGoBeforeStart: z.boolean().default(true),
  locationFromGoogle: z.union([
    z.string().max(0, { message: "Please select location from google" }),
    z.string().regex(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/),
  ]),
  fileName: z.string().optional(),
  file: z.instanceof(File).optional(),
  // photo: z.instanceof(FileList).optional(),
});

export type LocationSchema = z.infer<typeof locationValidationSchema>;
export const addLocation = createAsyncThunk(
  "locations/addLocation",
  async (location: LocationSchema) => {
    const response = await axiosClient.post("/location", location);
    return response.data;
  }
);
export const updateLocation = createAsyncThunk(
  "locations/updateLocation",
  async (location: LocationSchema) => {
    console.log("location", location);
    const response = await axiosClient.put("/location", location);
    return response.data;
  }
);
export const deleteLocation = createAsyncThunk(
  "locations/deleteLocation",
  async (id: LocationSchema["_id"]) => {
    const response = await axiosClient.delete(`/location/${id}`);
    return response.data;
  }
);
export const getLocations = createAsyncThunk(
  "locations/getLocations",
  async () => {
    const location = await axiosClient.get(`/location`);
    return location.data;
  }
);

export const emptyLocation: LocationSchema = {
  _id: "",
  id: "",
  name: "",
  startDescription: "",
  finishPoint: 0,
  needToGoBeforeStart: true,
  locationFromGoogle: "",
  finishTime: 0,
  fileName: "",
  file: undefined,
};

interface LocationsState {
  items: LocationSchema[];
  isLoading: boolean;
  error: string | null;
}

export const initialLocationState: LocationsState = {
  items: [],
  isLoading: false,
  error: null,
};

export const LocationsSlice = createSlice({
  name: "locations",
  initialState: initialLocationState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.items = action.payload;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        console.log(action, "action");
        //change the state from back end. Now it coming something wrong
        const data = { ...action.meta.arg };
        console.log(data, "data");
        data.fileName = data.file?.name;
        delete data.file;
        const index = state.items.findIndex((item) => item._id === data._id);
        state.items[index] = data;

        // return [...state, [index] = data}
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.items.push(action.meta.arg);
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        const data = action.meta.arg;
        console.log(action);
        state.items = state.items.filter((item) => item._id !== data);
      })
      .addMatcher(isPending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isRejected, (state) => {
        state.isLoading = false;
        state.error = "Request was rejected";
      });
    // .addDefaultCase((state) => {
    //   alert("123");
    //   state.isLoading = false;
    // });
  },
});

export const selectLocationVariants = (state: RootState) =>
  state.locations.items.map((location) => ({
    value: location._id,
    label: location.name,
  }));
// export const selectLocationById = (id: string) =>
//     createSelector(
//         (state: RootState) => state.locations.items,
//         (locations) => {
//           return id
//               ? locations.find((location) => location._id === id)
//               : emptyLocation;
//         }
//     );

// export const selectLocationById = (state: RootState, id: string) =>
//   state.locations.items.find((location) => location._id === id);

const getItems = (state: RootState) => state.locations.items;

export const selectLocationById = createSelector(
  [getItems, (state, id) => id],
  (items, id) => items.find((item) => item.id === id)
);
export const selectAllLocations = (state: RootState) => state.locations.items;
export const { setError: setLocationErrorMessage } = LocationsSlice.actions;
export default LocationsSlice.reducer;
