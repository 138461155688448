import React from "react";
import "./LeaderBoard.scss";
//component, which will show the leaderboard, will be a table with the following columns:
//1. Name
//2. Score
//4. Location
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import { getLeaderBoard } from "../../store/LeaderBoard.slice";

const LeaderBoard = ({ locationID }: Partial<{ locationID: string }>) => {
  const dispatch = useAppDispatch();
  const leaderBoard = useAppSelector((state) => state.leaderboard.data)
    .filter((row) => row.playingLocationId === locationID)
    .sort((a, b) => b.score - a.score);
  // const data = mockData.sort((a, b) => b.score - a.score);
  const clues = useAppSelector((state) =>
    state.clues.items.filter((clue) => clue.locationId === locationID)
  );

  const data = leaderBoard;
  const length = data.length;
  const classes = data.map((player, index) => {
    //instead of this we can use the formula: index * 100 / length
    const row = Math.ceil((index * 99) / (length - 1));
    return "row-" + row;
  });
  const timeOut = React.useRef<ReturnType<typeof setInterval> | null>(null);

  React.useEffect(() => {
    dispatch(getLeaderBoard());
  }, [locationID]);

  React.useEffect(() => {
    timeOut.current = setInterval(() => {
      dispatch(getLeaderBoard());
    }, 5000);
    return () => {
      if (timeOut.current) {
        // alert("clear");
        clearInterval(timeOut.current);
      }
    };
  }, []);
  const isAdmin = useAppSelector((state) => state.auth.token);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Place</TableCell>
            <TableCell>Name</TableCell>

            {isAdmin && <TableCell>Code</TableCell>}
            <TableCell>Location</TableCell>
            <TableCell>Score</TableCell>
            {isAdmin && <TableCell>Current playing Status</TableCell>}
            {isAdmin && <TableCell>Current Clue</TableCell>}
            <TableCell>Played Clues</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((player, index) => (
            <TableRow key={index} className={classes[index]}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{player.name}</TableCell>
              {isAdmin && <TableCell>{player.code}</TableCell>}
              <TableCell>{player.location}</TableCell>
              <TableCell>{player.score}</TableCell>
              {isAdmin && <TableCell>{player.playStatus}</TableCell>}
              {isAdmin && (
                <TableCell>
                  {clues.find((clue) => {
                    return clue._id === player.playingClueId;
                  })?.name || ""}
                </TableCell>
              )}

              <TableCell>
                {player.playedGames?.map((clueId) => {
                  const clue = clues.find(
                    (clue) => clue._id === clueId || clue.clueCode === clueId
                  );
                  return (
                    <div key={clueId}>
                      {clue?.name} - {clue?.point} points
                    </div>
                  );
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default LeaderBoard;
