import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { z } from "zod";

export const gameValidationSchema = z.object({
  startDescription: z.string(),
  // photo: z.instanceof(FileList).optional(),
});

export type GameConfig = z.infer<typeof gameValidationSchema>;

export type GameConfigState = GameConfig & {
  isLoading: boolean;
  error: string | undefined;
};
export const gameConfigDefaultValues: GameConfigState = {
  startDescription: "",
  isLoading: false,
  error: "",
};

export const GameConfigSlice = createSlice({
  name: "auth",
  initialState: gameConfigDefaultValues,
  reducers: {},
  //add matcher for all errors and loading
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
        state.error = "Error: " + action.error.message;
      });
  },
});

export default GameConfigSlice.reducer;
