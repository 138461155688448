import axios from "axios";
import { AppStore, RootState } from "../store/store";
import { Store } from "redux";
import { toast } from "react-toastify";

let store: Store<RootState>;

export const injectStore = (_store: Store<RootState>) => {
  store = _store;
};
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const res = error.response;

    toast.error(res.data.error);
    console.error(`Looks like there was a problem. Status Code: `, res);
    return Promise.reject(error);
  }
);
// axiosClient.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   // const { token } = store().getState().auth;
//   const token = localStorage.getItem("userToken"); //todo change
//   // const token = store.getState().auth.token;
//   console.log("token", token);
//   console.log("store", store.getState());
//   // const token = store.getState().session.token;
//   config.headers.Authorization = token ? "Bearer " + token : "";
//
//   return config;
// });

axiosClient.interceptors.request.use((config) => {
  if (config.method === "post" || config.method === "put") {
    const needFormData = Object.values(config.data).some(
      (v) => v instanceof File
    );
    if (needFormData) {
      const formData = new FormData();
      for (const [k, v] of Object.entries(config.data)) {
        if (v instanceof File) {
          formData.append(k, v);
        } else {
          formData.append(k, v + "");
        }
      }
      config.data = formData;
      config.headers["Content-Type"] = "multipart/form-data";
    }
  }
  const token = localStorage.getItem("userToken"); //todo change
  // const token = store.getState().auth.token;
  //console.log("token", token);
  //console.log("store", store.getState());
  // const token = store.getState().session.token;
  if (token) {
    config.headers.Authorization = token ? "Bearer " + token : "";
  }
  return config;
});
export default axiosClient;
