import { useAppSelector } from "../store/redux.hooks";

export const useLocationsHook = () => {
  const locations = useAppSelector((state) => state.locations.items);

  const variants = locations.map((location) => ({
    value: location._id,
    label: location.name,
  }));
  const selectLocationById = (id: string) => {
    const location = locations.find((location) => location._id === id);
    if (location) {
      return location;
    }
  };

  return { locations, locationsVariants: variants, selectLocationById };
};
