import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import React from "react";
import {
  FormControl,
  FormHelperText,
  TextFieldProps,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { SelectProps } from "@mui/material/Select/Select";

export type SelectOptions = { value: string | number; label: string };
const SelectBox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName> &
    SelectProps & {
      options?: Array<SelectOptions>;
    }
) => {
  const { fullWidth, name, label, options, control, ...rest } = props;
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(props);

  const labelId = `${name}-label`;
  console.log("props", props);

  return (
    <FormControl
      fullWidth={fullWidth === undefined ? true : fullWidth}
      error={!!error}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, ...field } }) => {
          return (
            <>
              <Select
                label={label}
                fullWidth={fullWidth}
                {...field}
                {...rest}
                onChange={(event, child) => {
                  // setV(event.target.value);
                  //todo find a better way to remove values that are not in the options
                  const selectedOptions = event.target.value;
                  // Filter out any selected options that are not in the menu options
                  if (!Array.isArray(selectedOptions)) {
                    onChange(event);
                    return;
                  }
                  const filteredSelectedOptions = selectedOptions.filter(
                    (option) =>
                      options?.some((menuOption) => menuOption.value === option)
                  );
                  onChange(filteredSelectedOptions);
                }}
              >
                {!rest.multiple && (
                  <MenuItem>
                    <em>-- None --</em>
                  </MenuItem>
                )}
                {options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {error && error.message && (
                <FormHelperText>{error.message}</FormHelperText>
              )}
            </>
          );
        }}
        name={name}
        control={control}
      ></Controller>

      {/*<Select*/}
      {/*  labelId={labelId}*/}
      {/*  id="demo-simple-select"*/}
      {/*  label={label}*/}
      {/*  value={value}*/}
      {/*  onChange={onChange}*/}
      {/*  {...rest}*/}
      {/*>*/}
      {/*  {options?.map((option) => (*/}
      {/*    <MenuItem key={option.value} value={option.value}>*/}
      {/*      {option.label}*/}
      {/*    </MenuItem>*/}
      {/*  ))}*/}
      {/*</Select>*/}
    </FormControl>

    // <TextField
    //   {...field}
    //   error={!!error}
    //   helperText={error && error.message && error.message}
    //   fullWidth={fullWidth === undefined ? true : fullWidth}
    //   {...rest}
    // />
  );
};

export default SelectBox;
