import { Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import {
  emptyLocation,
  LocationSchema,
  locationValidationSchema,
} from "../../store/Location.slice";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import {
  GameConfig,
  gameConfigDefaultValues,
  gameValidationSchema,
} from "../../store/GameConfig.slice";
import { useAppSelector } from "../../store/redux.hooks";

const GameConfigPage = () => {
  const gameConfig = useAppSelector((state) => state.gameConfig);
  const { register, handleSubmit } = useForm<GameConfig>({
    values: { ...gameConfig },
    defaultValues: gameConfigDefaultValues,
    resolver: zodResolver(gameValidationSchema),
    reValidateMode: "onSubmit",
  });

  return (
    <>
      <Typography variant="h3">Խաղի պարամետրեր</Typography>
      <form
        onSubmit={handleSubmit((data) => {
          console.log("submit");
        })}
      >
        <textarea {...register("startDescription")} />
      </form>
    </>
  );
};

export default GameConfigPage;
