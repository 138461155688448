import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosClient from "../utils/apiClient";
import { z } from "zod";
import { User, userSchema } from "../components/Users/Users.types";
import { RootState } from "./store";
// import LeaderBoard from "../features/LeaderBoard/LeaderBoard";

export const leaderBoardValidationSchema = userSchema.extend({
  name: z.string().nonempty(),
  score: z.coerce.number().min(0),
  location: z.string().nonempty(),
});
export type LeaderBoardSlice = {
  error: string;
  data: z.infer<typeof leaderBoardValidationSchema>[];
};
const initialLeaderBoardState: LeaderBoardSlice = {
  error: "",
  data: [],
};

export const getLeaderBoard = createAsyncThunk(
  "leaderBoard/getLeaderBoard",
  async (arg, { getState }) => {
    const leaderboard = await axiosClient.get(`/leaderboard`);
    const state = getState() as RootState;

    return leaderboard.data.map((user: User) => {
      const currentLocation = state.locations.items.find(
        (loc) => loc._id === user.playingLocationId
      );

      return {
        ...user,
        name: user.teamName,
        score: user.allPoint,
        location: currentLocation?.name,
        // location: user.,
      } as z.infer<typeof leaderBoardValidationSchema>;
    });
    // .sort((a, b) => b.score - a.score);
    // return leaderboard.data;
  }
);
export const LeaderBoardSlice = createSlice({
  name: "leaderBoard",
  initialState: initialLeaderBoardState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeaderBoard.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
  },
});
export default LeaderBoardSlice.reducer;
