import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface ConfirmDialogProps {
  open: boolean;
  item: string;
  result: (value: boolean) => void;
}

const ConfirmDialog = ({ open, result, item }: ConfirmDialogProps) => {
  const handleClick = (value: boolean) => {
    result(value);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        {`Pressing 'Yes' will delete ${item}.`}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick(false)}>No</Button>
        <Button onClick={() => handleClick(true)}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
