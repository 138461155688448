import React from "react";
import { AppBar, Avatar, Box, IconButton, Toolbar } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import { useAppDispatch } from "../../store/redux.hooks";
import { logOut } from "../../store/Auth.slice";
import { useAuthAdmin } from "../../hooks/useAuthAdmin.hook";

const Header = () => {
  const { name, loggedIn } = useAuthAdmin();
  const dispatch = useAppDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <AppBar position="static">
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button href={"/"} sx={{ color: "#fff" }}>
              LeaderBoard
            </Button>
            <Button href={"/admin"} sx={{ color: "#fff" }}>
              Admin
            </Button>
            {/*<Button href={"/admin/games"} sx={{ color: "#fff" }}>*/}
            {/*  Games*/}
            {/*</Button>*/}
            <Button href={"/admin/clues"} sx={{ color: "#fff" }}>
              Clues
            </Button>
            <Button href={"/admin/users"} sx={{ color: "#fff" }}>
              Users
            </Button>
            <Button href={"/admin/locations"} sx={{ color: "#fff" }}>
              Locations
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {loggedIn ? (
              <>
                <IconButton sx={{ p: 0 }} title={name}>
                  <Avatar alt={name} sx={{ bgcolor: "#fff", color: "#000" }}>
                    {name[0]}
                  </Avatar>
                </IconButton>

                <Button
                  color="inherit"
                  onClick={() => dispatch(logOut())}
                  title={"Log out"}
                >
                  Log out
                </Button>
              </>
            ) : (
              <Button href={"/signIn"} sx={{ color: "#fff" }}>
                Log in
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
