// import Footer from "components/footer";
// import Header from "components/header";
// import Toast from "components/Toast/Toast";
import React from "react";
import Container from "@mui/material/Container";

import { Outlet } from "react-router-dom";
import Header from "../Header/Header.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthAdmin } from "../../hooks/useAuthAdmin.hook";
import { Box } from "@mui/system";

const Layout = () => {
  // const state = useAppSelector((state) => state.toast);
  const { loggedIn } = useAuthAdmin();
  return (
    <>
      <ToastContainer />
      <Box className="layout">
        {loggedIn && <Header />}
        <Container
          // component={Paper}
          component="main"
          className="main"
        >
          <Outlet />
        </Container>
        {/*<Toast {...state} />*/}
        {/*<Footer />*/}
      </Box>
    </>
  );
};
export default Layout;
