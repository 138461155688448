import {
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import { User, UsersState } from "../components/Users/Users.types";
import {
  addUser,
  deleteUser,
  getUsers,
  updateUser,
} from "../components/Users/Users.thunks";

// export const userDefaultValues: User = {
//   teamName: "",
//   // id: "",
//   _id: "",
//   role: "player",
//   adminId: "",
//   // chatTo: "",
//   code: "",
//   verificationCode: "",
//   playingLocationId: null,
//   playingLocationSteps: [],
//   playingLocationStepsNames: [],
//   playingGameId: null,
//   playingGameTime: null,
//   playingLocationTime: null,
//   playedGames: [],
//   playStatus: "",
//   userPoint: 0,
//   allPoint: 0,
// };
const initialUsersState: UsersState = {
  items: [],
  isLoading: false,
  error: null,
};
export const UsersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.items = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        //change the state from back end. Now it coming something wrong
        const data = action.payload; //action.meta.arg;
        const index = state.items.findIndex((item) => item._id === data._id);
        state.items[index] = data;
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.items.push(action.payload);
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const data = action.meta.arg;
        state.items = state.items.filter((item) => item._id !== data);
        return state;
      })
      .addMatcher(isPending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isFulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;

        state.error = "Error: " + action.error.message;
      });
  },
});

export const { setError } = UsersSlice.actions;

export const selectUsers = (state: RootState) => state.users.items;

export const selectUserByIdForForm = createSelector(
  [selectUsers, (state, id) => id],
  (items, id) => {
    const us = items.find((item) => item._id === id);
    if (!us) return undefined;

    return {
      teamName: us.teamName,
      role: us.role,
      admin: us.admin,
      adminId: us.adminId,
      code: us.code,
      playingLocationSteps: us.playingLocationSteps,
      verificationCode: us.verificationCode,
    };
  }
);

export default UsersSlice.reducer;
