import React from "react";
import { Avatar, Button, Card, CardContent, Typography } from "@mui/material";
import InputText from "../../components/InputText/InputText";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../store/redux.hooks";
import { logIn } from "../../store/Auth.slice";
import { toast } from "react-toastify";

export interface ISignInForm {
  name: string;
  password: string;
}
const SignInPage = () => {
  const dispatch = useAppDispatch();

  const { handleSubmit, control, setError } = useForm<ISignInForm>({
    values: {
      name: "",
      password: "",
    },
  });
  const onSubmit = (data: ISignInForm) => {
    dispatch(logIn(data))
      .unwrap()
      .then(() => {
        toast.success("Signed in successfully");
      });
  };
  return (
    <Card
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className="SignInForm"
      sx={{ maxWidth: 400, margin: "auto" }}
    >
      <CardContent className="inner">
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <InputText
          name="name"
          label={"Login"}
          autoComplete="login"
          control={control}
          fullWidth
        />

        <InputText
          name="password"
          control={control}
          margin="normal"
          label={"Password"}
          type="password"
          fullWidth
          autoComplete="password"
        />

        <Button
          type="submit"
          variant="contained"
          // disabled={isLoading}
          sx={{ mb: 2, mt: 1 }}
          fullWidth
        >
          Sign in
          {/*{isLoading && "..."}*/}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SignInPage;
