import React, { useEffect } from "react";

import MainPage from "./pages/MainPage/Main.page";
import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout.component";
import AdminPage from "./pages/AdminPage/Admin.page";
import GameConfigPage from "./pages/GameConfigPage/GameConfigPage";
import UsersPage from "./pages/UsersPage/Users.page";
import CluesPage from "./pages/CluesPage/Clues.page";
import { useAppDispatch } from "./store/redux.hooks";
import { getLocations } from "./store/Location.slice";
import LocationsPage from "./pages/LocationsPage/Locations.page";
import SignInPage from "./pages/SignIn/SignIn.page";
import { useAuthAdmin } from "./hooks/useAuthAdmin.hook";
// import { ErrorBoundaries } from "./components/ErrorBoudary/ErrorBoundary";
import { getUsers } from "./components/Users/Users.thunks";
import LeaderBoardPage from "./pages/LeaderBoardPage/LeaderBoardPage";
import BottlePage from "./pages/BottlePage/Bottle.page";
import { getClues } from "./components/CluesList/Clues.slice";

type ProtectedRouteType = {
  isAllowed: boolean;
  redirectPath?: string;
};
const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/",
}: ProtectedRouteType) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getUsers());
    dispatch(getClues());
  }, []);

  const { loggedIn } = useAuthAdmin();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path="/admin"
          element={
            <ProtectedRoute isAllowed={loggedIn} redirectPath={"/signIn"} />
          }
        >
          <Route index element={<MainPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/games" element={<GameConfigPage />} />
          <Route path="/admin/users" element={<UsersPage />} />
          <Route path="/admin/clues" element={<CluesPage />} />
          <Route path="/admin/locations" element={<LocationsPage />} />
        </Route>
        <Route
          element={
            <ProtectedRoute isAllowed={!loggedIn} redirectPath={"/admin/"} />
          }
        >
          <Route path="/signIn" element={<SignInPage />} />
        </Route>
        {/*todo: refactor*/}
        <Route path="/" index={true} element={<LeaderBoardPage />} />
        {/*<Route*/}
        {/*  path="/leaderboard/"*/}
        {/*  index={true}*/}
        {/*  element={<LeaderBoardPage />}*/}
        {/*/>*/}
        <Route
          path="/leaderboard/:id?"
          index={true}
          element={<LeaderBoardPage />}
        />
        <Route path={"/bottle"} element={<BottlePage />} />
      </Route>
    </Routes>
  );
}

export default App;
