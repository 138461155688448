import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { AttachFile, Delete as DeleteIcon } from "@mui/icons-material";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = TextFieldProps &
  UseControllerProps & {
    fileName: TName;
  };

const InputFile = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext();

  const { name, size, margin, fullWidth, fileName, ...rest } = props;
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const { field: fileNameField } = useController({
    name: fileName,
    control,
  });

  const [uploadingFile, setUploadingFile] = useState<File[] | undefined>([]);

  const onFileDrop = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      const newFile = Object.values(target.files).map((file: File) => file);
      if (newFile.length === 0) return; // skip if no files selected

      if (uploadingFile && uploadingFile.length >= 1)
        return alert("Only a single image allowed");
      setUploadingFile(newFile);
      field.onChange(newFile[0]);
    },
    [field, uploadingFile]
  );

  const handleFileRemove = () => {
    setUploadingFile(undefined);
    fileNameField.onChange("");
  };
  // onsubmit, if uploadingFile is not undefined, change filename to uploadingFile[0].name

  useEffect(() => {
    if (uploadingFile && uploadingFile.length > 0) {
      fileNameField.onChange(uploadingFile[0].name);
    }
  }, [uploadingFile, fileNameField]);

  return (
    <>
      {fileNameField?.value?.length > 0 ? (
        <Paper variant={"outlined"} sx={{ padding: 1 }}>
          <Typography variant="subtitle1">
            <AttachFile style={{ verticalAlign: "middle" }} />
            {fileNameField.value}
            <IconButton onClick={handleFileRemove}>
              <DeleteIcon />
            </IconButton>
          </Typography>
        </Paper>
      ) : (
        <Controller
          defaultValue={""}
          render={({ field: { name, onBlur, ref } }) => (
            <TextField
              name={name}
              onBlur={onBlur}
              ref={ref}
              inputRef={ref}
              type="file"
              onChange={onFileDrop}
              error={!!error}
              helperText={error && error.message && error.message}
              fullWidth={fullWidth === undefined ? true : fullWidth}
              {...rest}
            />
          )}
          name={name}
        />
      )}
    </>
  );
};

export default InputFile;
