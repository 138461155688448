import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowId,
  GridToolbar,
} from "@mui/x-data-grid";
import { GridCol } from "../../utils/types";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Fab, Zoom } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

type GridListProps<T> = {
  handleEditClick?: (id: string) => void;
  handleAddClick?: () => void;
  handleDeleteClick?: (id: string) => void;
  columns: GridCol<T>[];
  rows: T[];
  loading?: boolean;
};

function GridList<T>({
  handleEditClick,
  handleDeleteClick,
  handleAddClick,
  columns,
  rows,
  loading = false,
}: GridListProps<T>) {
  const [selectedId, setSelectedId] = useState<GridRowId>("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const confirmDelete = (confirmed: boolean) => {
    if (confirmed && selectedId && handleDeleteClick) {
      handleDeleteClick(selectedId as string);
    }
    setSelectedId("");
  };

  useEffect(() => {
    setConfirmDialogOpen(!!selectedId);
  }, [selectedId]);

  const actionsArray: GridCol<T>[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const actions = [];
        if (handleEditClick) {
          actions.push(
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit"
              className="textPrimary"
              onClick={() => {
                handleEditClick(id as string);
              }}
              color="inherit"
              key={1}
            />
          );
        }
        if (handleDeleteClick) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              onClick={() => {
                setSelectedId(id as string);
              }}
              color="inherit"
              key={2}
            />
          );
        }
        return actions;
      },
    },
  ];

  return (
    <>
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          item={"this item" + selectedId}
          result={confirmDelete}
        />
      )}
      <DataGrid
        sx={{
          borderColor: "primary.main",
          "& .MuiDataGrid-cell": {
            borderColor: "primary.main",
          },
        }}
        slots={{ toolbar: GridToolbar }}
        loading={loading}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        columns={[...columns, ...actionsArray]}
        rows={rows}
        getRowId={(row) => {
          try {
            return row._id;
          } catch (e) {
            console.log("error grid", e);
          }
        }}
      />
      {handleAddClick && (
        <Zoom in>
          <Fab
            sx={{ position: "absolute" }}
            color="primary"
            aria-label="add"
            onClick={handleAddClick}
          >
            <AddIcon />
          </Fab>
        </Zoom>
      )}
    </>
  );
}

export default GridList;
