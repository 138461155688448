import React, { useCallback, useEffect, useState } from "react";
import { UseFormReturn, FormProvider } from "react-hook-form";
import { Grid, Button, Typography } from "@mui/material";

import InputText from "../InputText/InputText";
import SelectBox from "../SelectBox/SelectBox";
import { useAppSelector } from "../../store/redux.hooks";
import { selectLocationVariants } from "../../store/Location.slice";
import InputFile from "../InputFile/InputFile.component";
import {
  ClueFormSchema,
  clueTypes,
  clueTypesOptions,
} from "../CluesList/Clues.slice";
import { roleOptions } from "../Users/Users.types";
interface ClueForm {
  defaultValues: ClueFormSchema | undefined;
  onSubmit: (data: ClueFormSchema) => void;
  form: UseFormReturn<ClueFormSchema>;
}
const ClueForm = ({ defaultValues, onSubmit, form }: ClueForm) => {
  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = form;
  // console.log(form, "form");

  useEffect(() => {
    // console.log("defaultValues", defaultValues);
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);
  const selectOptions = useAppSelector(selectLocationVariants);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item xs={6}>
              {/*<input {...register("name")} />*/}
              <InputText
                fullWidth
                name={"name"}
                label={"Clue Name"}
                variant={"outlined"}
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              {/*<input {...register("name")} />*/}
              <InputText
                fullWidth
                name={"clueCode"}
                label={"Clue Code"}
                variant={"outlined"}
                control={control}
              />
            </Grid>

            <Grid item xs={6}>
              <InputText
                fullWidth={true}
                rows={4}
                multiline
                control={control}
                name={"description"}
                label="Description"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                fullWidth={true}
                rows={4}
                multiline
                control={control}
                name={"fullDescription"}
                label="Full Description"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectBox
                label={"Location"}
                name={"locationId"}
                options={selectOptions}
                control={control}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <InputText
                control={control}
                fullWidth={true}
                rows={4}
                name="locationFromGoogle"
                label="Google Location"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <InputFile
                //control={control}
                name={"file"}
                variant="outlined"
                fileName={"fileName"}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectBox
                control={control}
                options={clueTypesOptions}
                fullWidth={true}
                rows={4}
                name="clueType"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                fullWidth
                type={"number"}
                name={"point"}
                label={"Point"}
                variant={"outlined"}
                control={control}
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                control={control}
                fullWidth={true}
                rows={4}
                name="maxPlayersSameTime"
                label="Max players"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <InputText
                control={control}
                fullWidth={true}
                rows={4}
                name="playTime"
                label="Play time"
                variant="outlined"
              />
            </Grid>

            {/*<Grid item xs={12}></Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*  <Button type={"submit"} variant={"contained"}>*/}
            {/*    Submit*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default ClueForm;
