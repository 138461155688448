import React, { useEffect } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { Grid, Button, Typography } from "@mui/material";

import InputText from "../InputText/InputText";

import { LocationSchema } from "../../store/Location.slice";

import InputFile from "../InputFile/InputFile.component";
interface LocationForm {
  // defaultValues: LocationSchema | undefined;
  onSubmit: (data: LocationSchema) => void;
  form: UseFormReturn<LocationSchema>;
  isDisabled?: boolean;
}
const LocationForm = ({
  // defaultValues,
  onSubmit,
  form,
  isDisabled = false,
}: LocationForm) => {
  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    watch,

    formState: { errors, isDirty, isValid },
  } = form;

  // useEffect(() => {
  //   reset(defaultValues);
  // }, [defaultValues]);
  // useEffect(() => {
  //   console.log("watchAllFields", watchAllFields);
  // }, [watchAllFields]);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit, (a) => {
          console.log(a, "aa");
        })}
      >
        {/*{isValid ? "true" : "false"}*/}
        {/*{errors?.map((err) => (*/}
        {/*  <div>err</div>*/}
        {/*))}*/}
        {/*isDirty: {isDirty ? "true" : "false"}*/}
        <Grid spacing={2} container>
          <Grid item xs={6}>
            {/*<input {...register("name")} />*/}
            <InputText
              fullWidth
              name={"name"}
              label={"Location Name"}
              variant={"outlined"}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFile name={"file"} fileName={"fileName"} />
          </Grid>
          <Grid item xs={6}>
            <InputText
              control={control}
              fullWidth={true}
              rows={4}
              name="locationFromGoogle"
              variant="outlined"
              label={"Location from google"}
            />
          </Grid>
          <Grid item xs={6}>
            {/*<CheckBox {...register("needToGoBeforeStart")} />*/}
            <Typography>Պետք է հասնել տարածք, սկսելու համար</Typography>
            <input type={"checkbox"} {...register("needToGoBeforeStart")} />
            {/*<InputText*/}
            {/*  control={control}*/}
            {/*  fullWidth={true}*/}
            {/*  rows={4}*/}
            {/*  name="location"*/}
            {/*  variant="outlined"*/}
            {/*  label={"Location from google"}*/}
            {/*/>*/}
          </Grid>
          <Grid item xs={6}>
            <InputText
              control={control}
              fullWidth={true}
              rows={4}
              multiline
              name="startDescription"
              type={"text"}
              variant="outlined"
              label={"Start Description"}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <InputText
              control={control}
              fullWidth={true}
              name="finishTime"
              variant="outlined"
              label={"Time to finish (minutes)"}
            />
          </Grid>
          <Grid item xs={6}>
            <InputText
              control={control}
              fullWidth={true}
              name="finishPoint"
              variant="outlined"
              label={"Point to finish (and start level up)"}
              type={"number"}
              inputProps={{
                step: "100",
              }}
            />
          </Grid>
          {/*<Grid item xs={12}></Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <Button type={"submit"} variant={"contained"} disabled={isDisabled}>*/}
          {/*    Submit*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LocationForm;
