import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserFormType } from "./Users.types";
import axiosClient from "../../utils/apiClient";

export const getUsers = createAsyncThunk("users/getUsers", async () => {
  const users = await axiosClient.get(`/user`);
  return users.data;
});
export const addUser = createAsyncThunk(
  "users/addUser",
  async (user: UserFormType) => {
    const response = await axiosClient.post("/user", user);
    return response.data;
  }
);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  //todo change type
  async (user: UserFormType & { _id: string }) => {
    const response = await axiosClient.put("/user", user);
    return response.data;
  }
);
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  //todo change type
  async (id: string) => {
    const response = await axiosClient.delete(`/user/${id}`);
    return response.data;
  }
);
