import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";

import LocationForm from "../../components/LocationForm/LocationForm.component";

import {
  addLocation,
  deleteLocation,
  emptyLocation,
  LocationSchema,
  locationValidationSchema,
  selectAllLocations,
  selectLocationById,
  setLocationErrorMessage,
  updateLocation,
} from "../../store/Location.slice";
import FormDialog from "../../components/FormDialog/FormDialog.component";
import { useForm } from "react-hook-form";

import { GridCol } from "../../utils/types";
import { GridRowId } from "@mui/x-data-grid";

import { zodResolver } from "@hookform/resolvers/zod/dist/zod";

import GridList from "../../components/GridList/GridList.component";
import { InfoOutlined } from "@mui/icons-material";

import { filesBaseUrl } from "../../utils/filesBaseUrl";
import { toast } from "react-toastify";

const LocationsPage = () => {
  const dispatch = useAppDispatch();
  const locations = useAppSelector(selectAllLocations);
  const isLoading = useAppSelector((state) => state.locations.isLoading);
  const errorMessage = useAppSelector((state) => state.locations.error);

  const [selectedID, setSelectedID] = React.useState<LocationSchema["_id"]>("");
  // const [selectedLocation, setSelectedLocation] =
  //   useState<LocationSchema>(emptyLocation); //todo change
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<"Add" | "Edit">("Add");
  const selectedLocation = useAppSelector((state) =>
    selectLocationById(state, selectedID)
  );

  const form = useForm<LocationSchema>({
    values: { ...emptyLocation, ...selectedLocation },
    resolver: zodResolver(locationValidationSchema),
    reValidateMode: "onSubmit",
  });
  const { reset, handleSubmit, watch } = form;
  useEffect(() => {
    // setSelectedLocation(
    //   locations.find((loc) => loc._id === selectedID) || emptyLocation
    // );
    reset();
  }, [selectedID]);

  const handleAddClick = () => {
    setSelectedID("");
    setIsOpen(true);
    setType("Add");
  };
  const handleEditClick = (id: GridRowId) => {
    setSelectedID(id as string); //change type
    setIsOpen(true);
    setType("Edit");
    dispatch(setLocationErrorMessage(""));
    reset();
  };

  // // need to implement is submitting from useForm
  // useEffect(() => {
  //   if (isSubmitting) {
  //     setFileList([]);
  //     setSingleFile([]);
  //   }
  // }, [isSubmitting]);
  //
  // //
  const handleDeleteClick = (id: string) => {
    dispatch(deleteLocation(id))
      .unwrap()
      .then(() => {
        toast.success("Location is updated");
      });
  };
  const onSubmit = (data: LocationSchema) => {
    if (type === "Add") {
      dispatch(addLocation(data))
        .unwrap()
        .then(() => {
          toast.success("Location is added");
        });
    } else {
      dispatch(updateLocation(data))
        .unwrap()
        .then(() => {
          toast.success("Location is updated");
        });
    }
  };
  const columns: GridCol<LocationSchema>[] = [
    // { field: "_id", headerName: "ID", width: 100 },
    { field: "name", headerName: "ԱՆուն", width: 210 },
    {
      field: "startDescription",
      headerName: "Նկարագրություն",
      width: 200,
      sortable: false,
      resizable: true,
      renderHeader: (params) => {
        return (
          <>
            {params.colDef.headerName}{" "}
            <Tooltip title={"Տարածքի սկզբում գրվող նկարագրություն"}>
              <InfoOutlined fontSize={"small"} />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "finishPoint",
      headerName: "Միավորնեr ավարտելու համար",
      width: 100,
    },
    {
      field: "needToGoBeforeStart",
      headerName: "Պետք է հասնել տարածք, սկսելու համար",
      width: 100,
      type: "boolean",
    },
    { field: "finishTime", headerName: "Finish time", width: 100 },
    {
      field: "fileName",
      headerName: "File",
      width: 200,
      renderCell: ({ value }) => {
        return (
          <>
            <Button target={"_blank"} href={filesBaseUrl + value}>
              {value}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*<Backdrop open={isLoading} style={{ zIndex: 1 }}>*/}
      {/*  <CircularProgress color="inherit" />*/}
      {/*</Backdrop>*/}
      <Typography variant="h2">Տարածքներ</Typography>

      {errorMessage && (
        <Snackbar
          open={errorMessage?.length > 0}
          autoHideDuration={3000}
          // onClose={handleClose}
        >
          <Alert
            // onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <GridList<LocationSchema>
        handleEditClick={handleEditClick}
        handleAddClick={handleAddClick}
        handleDeleteClick={handleDeleteClick}
        columns={columns}
        rows={locations}
        loading={isLoading}
      />
      <FormDialog
        // key={selectedID}
        title={`${type} Locations Form`}
        open={isOpen}
        isLoading={isLoading}
        actions={[
          <Button
            key={"1_SaveButton"}
            onClick={handleSubmit(onSubmit, (a) => {
              console.log("Asfasf", a);
            })}
            disabled={isLoading}
          >
            Save
          </Button>,
        ]}
        handleClose={() => {
          setIsOpen(false);
        }}
      >
        <>
          {errorMessage && (
            <Typography variant={"h5"} color={"red"}>
              {errorMessage}
            </Typography>
          )}
          <LocationForm
            form={form}
            // defaultValues={selectedLocation}
            onSubmit={onSubmit}
            isDisabled={isLoading}
          />
        </>
      </FormDialog>
    </>
  );
};

export default LocationsPage;
